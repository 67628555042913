export default [
  {
    title: "Holzbaugerechter Planungsprozess: Gut geplant ist halb gebaut",
    host: "Bruderverlag",
    hostUrl: "https://www.bauenmitholz.de/",
    date: "2021-11-16",
    place: "",
  },
  {
    title: "Holzbaugerechte Planungsprozesse",
    host: "81fünf",
    hostUrl: "https://www.81fuenf.de/",
    date: "2021-09-29",
    place: "Ostfildern",
  },
  {
    title: "Holzbaugerechte Planungsprozesse",
    host: "81fünf",
    hostUrl: "https://www.81fuenf.de/",
    date: "2021-09-14",
    place: "Essen",
  },
  {
    title: "Holzbaugerechte Planungsprozesse",
    host: "81fünf",
    hostUrl: "https://www.81fuenf.de/",
    date: "2021-09-01",
    place: "Lüneburg",
  },
  {
    title: "Arbeitsvorbereitung – Übersicht für Betriebswirte",
    host: "L&P Unternehmensberatung",
    hostUrl: "https://www.lup-unternehmensberatung.de/",
    date: "2021-04-29",
    place: "Webinar",
  },
  {
    title: "Arbeitsvorbereitung im Holzbau",
    host: "81fünf",
    hostUrl: "https://www.81fuenf.de/",
    date: "2021-04-29",
    place: "Webinar",
  },
  {
    title: "Arbeitsvorbereitung im Holzbau",
    host: "81fünf",
    hostUrl: "https://www.81fuenf.de/",
    date: "2021-04-27",
    place: "Webinar",
  },
  {
    title: "Arbeitsvorbereitung im Holzbau",
    host: "81fünf",
    hostUrl: "https://www.81fuenf.de/",
    date: "2021-04-21",
    place: "Webinar",
  },
  {
    title: "Arbeitsvorbereitung im Holzbau",
    host: "81fünf",
    hostUrl: "https://www.81fuenf.de/",
    date: "2021-04-20",
    place: "Webinar",
  },
  {
    title: "Integrative Planung mit BIM",
    eventUrl:
      "https://www.bauen-mit-holz.nrw/aktuelles/online-seminar-aufstockungen-2021/",
    host: "Bauen mit Holz.NRW",
    hostUrl: "https://www.bauen-mit-holz.nrw/",
    date: "2021-04-14",
    place: "Webinar",
  },
  {
    title: "Vorteile des mehrgeschossigen Holzbaus",
    host: "Immobiliengruppe Metropol",
    hostUrl: "https://www.metropol.de/",
    date: "2021-03-23",
    place: "Webinar",
  },
  {
    title: "Was ist anders im Holzbau?",
    host: "Volkswohnung",
    hostUrl: "https://www.volkswohnung.com/",
    date: "2021-01-28",
    place: "Webinar",
  },
  {
    title: "Planungsprozess im Holzbau",
    host: "Symposium",
    date: "2020-09-10",
    place: "Erftstadt",
  },
  {
    title: "Die Zukunft der Baubranche",
    host: "81fünf",
    hostUrl: "https://www.81fuenf.de/",
    date: "2020-10-16",
    place: "Borken",
  },
  {
    title: "Holzbau mit System",
    host: "Bauen mit Holz.NRW",
    hostUrl: "https://www.bauen-mit-holz.nrw/",
    date: "2020-11-18",
    place: "Webinar",
  },
  {
    title: "Planungsprozesse im Holzbau",
    host: "Bauen mit Holz.NRW",
    hostUrl: "https://www.bauen-mit-holz.nrw/",
    date: "2020-11-18",
    place: "Webinar",
  },
  {
    title: "Videokonferenzen – so geht's",
    host: "81fünf",
    hostUrl: "https://www.81fuenf.de/",
    date: "2020-05-07",
    place: "Webinar",
  },
  // {
  //   title: "Klimakrise & Holzbau",
  //   host: "Prause Holzbauplanung",
  //   date: "2020-05-05",
  //   place: "Webinar",
  // },
  {
    title: "Der ideale Ablauf einer Holzbauplanung",
    host: "81fünf",
    hostUrl: "https://www.81fuenf.de/",
    date: "2020-04-23",
    place: "Webinar",
  },
  {
    title: "Klimakrise, Digitalisierung & Holzbau",
    host: "Frühjahrstagung der Holzbauverbände",
    hostUrl:
      "https://d-h-v.de/news/detail/?tx_news_pi1%5Bnews%5D=78&tx_news_pi1%5Bcontroller%5D=News&tx_news_pi1%5Baction%5D=detail&cHash=09dbc615a417fd796abaa6161db42a89",
    date: "2020-03-13",
    place: "Berlin",
  },
  {
    title: "BIM – nur ein Schlagwort?",
    host: "Holzbau Deutschland",
    hostUrl: "https://www.holzbau-deutschland.de/",
    date: "2020-01-31",
    place: "Stuttgart",
  },
  {
    title: "Digitalisierung im Holzbau",
    host: "Architekturkongress Messe Stuttgart",
    date: "2020-01-29",
    place: "Stuttgart",
  },
  {
    title: "Modulbau und Hybridbau",
    host: "81fünf – HB9",
    date: "2019-11-30",
    place: "Arnsberg",
  },
  {
    title: "BIM – Hilfe beim Produktdatenmanagement",
    host: "IQUH",
    date: "2019-10-25",
    place: "Rutesheim",
  },
  {
    title: "Zukunft der Automatisierung",
    host: "81fünf – HB7",
    date: "2019-10-19",
    place: "Ihlienworth",
  },
  {
    title: "Positionierung",
    host: "81fünf – HB7",
    date: "2019-10-18",
    place: "Ihlienworth",
  },
  {
    title: "BSP und der Holzhandel",
    host: "Holzland",
    date: "2019-10-10",
    place: "Olsberg",
  },
  {
    title: "Kann BIM helfen?",
    host: "81fünf Planer-Erfa",
    date: "2019-10-09",
    place: "Berlin",
  },
  {
    title: "Holzbau aus Sicht der Werkstattplanung",
    host: "Holzbau Summer School",
    date: "2019-09-25",
    place: "Hamburg",
  },
  {
    title: "Disruption des Holzbaus",
    host: "81fünf Herbsttagung",
    date: "2019-09-13",
    place: "Freiburg",
  },
  {
    title: "Digitale Planungsprozesse im Holzbau",
    host: "Verband Niedersächsischer Zimmermeister",
    date: "2019-08-29",
    place: "Bad Zwischenahn",
  },
  {
    title: "1 – Einführung BIM im Holzbau",
    host: "Holzbau Baden",
    date: "2019-05-15",
    place: "Freiburg",
  },
  {
    title: "2 – Planungsprozesse im Holzbau",
    host: "Holzbau Baden",
    date: "2019-05-15",
    place: "Freiburg",
  },
  {
    title: "3 – Digitales Aufmaß",
    host: "Holzbau Baden",
    date: "2019-05-15",
    place: "Freiburg",
  },
  {
    title: "4 – Digitalisierung im Bauwesen",
    host: "Holzbau Baden",
    date: "2019-05-15",
    place: "Freiburg",
  },
  {
    title: "5 – BIM beginnen",
    host: "Holzbau Baden",
    date: "2019-05-15",
    place: "Freiburg",
  },
  // {
  //   title: "Planungsprozesse",
  //   host: "Prause Holzbauplanung",
  //   date: "2019-05-13",
  //   place: "Lindlar",
  // },
  {
    title: "Planungsprozesse und BIM",
    host: "81fünf – HB9",
    date: "2019-05-11",
    place: "Spangenberg",
  },
  {
    title: "BIM – Chancen und Risiken für den Holzhandel",
    host: "Holzland",
    date: "2019-04-08",
    place: "Amsterdam",
  },
  {
    title: "Anforderungen an eine umfassende Arbeitsvorbereitung",
    host: "High Tech Abbundverband",
    date: "2019-01-24",
    place: "Kirchberg",
  },
  {
    title: "DHWR + BIM",
    host: "DHWR",
    date: "2018-11-30",
    place: "Berlin",
  },
  {
    title: "BIM Leitfaden und BIM Forschung",
    host: "DHV Herbsttagung",
    date: "2018-11-23",
    place: "Ettlingen",
  },
  {
    title: "Digitale Holzbauplanung",
    host: "Building Smart Rheinland bim next",
    date: "2018-11-21",
    place: "Köln",
  },
  {
    title: "Kernaufgaben des Chefs",
    host: "81fünf – HB7",
    date: "2018-10-12",
    place: "Lohne",
  },
  {
    title: "BIM in den Niederlanden und Deutschland",
    host: "81fünf Herbsttagung",
    date: "2018-09-21",
    place: "Amsterdam",
  },
  {
    title: "Holzbauweise im Mehrfamilienhausbau",
    host: "vdw Niedersachsen + Bremen",
    date: "2018-09-05",
    place: "Lüneburg",
  },
  {
    title: "Hausbau einfach gemacht",
    host: "Forum Holzbau",
    date: "2018-08-31",
    place: "Klagenfurt",
  },
  {
    title: "Einführung in die Planungsmethode BIM",
    host: "Handwerkskammer Erfurt",
    date: "2018-05-25",
    place: "Erfurt",
  },
  {
    title: "Die Planungsprozesse im Holzbau sind schon lange BIM",
    host: "Building Smart Deutschland",
    date: "2018-04-18",
    place: "Nürnberg",
  },
  {
    title: "Digitalisierung",
    host: "81fünf – HB6",
    date: "2018-04-13",
    place: "Bad Lippspringe",
  },
  {
    title: "Werkplanung – BIM gerecht umgesetzt",
    host: "Beuth Holzbauforum",
    date: "2018-03-22",
    place: "Berlin",
  },
  {
    title: "BIM – nur ein Schlagwort?",
    host: "Frühjahrstagung der Holzbauverbände",
    date: "2018-03-17",
    place: "Berlin",
  },
  {
    title: "Digitalisierung",
    host: "GIH Baden-Württemberg",
    date: "2018-02-16",
    place: "Feuchtwangen",
  },
  {
    title: "BIM – was steckt konkret dahinter?",
    host: "Verband HIGH-TECH-ABBUND",
    date: "2018-01-25",
    place: "Kassel",
  },
  {
    title: "BIM",
    host: "Zimmerer-Innung Borken",
    date: "2018-01-11",
    place: "Borken",
  },
].map(talk => ({ ...talk, date: new Date(talk.date) }))

// Titel;Veranstalter;Datum;Ort
// Videokonferenzen - so geht's;81fünf;07.05.2020;Webinar
// Klimakrise & Holzbau;Prause Holzbauplanung;05.05.2020;Webinar
// Der ideale Ablauf einer Holzbauplanung;81fünf;23.04.2020;Webinar
// Klimakrise, Digitalisierung & Holzbau;Frühjahrstagung der Holzbauverbände;13.03.2020;Berlin
// BIM - nur ein Schlagwort?;Holzbau Deutschland;31.01.2020;Stuttgart
// Digitalisierung im Holzbau;Architekturkongress Messe Stuttgart;29.01.2020;Stuttgart
// Modulbau und Hybridbau;81fünf - HB9;30.11.2019;Arnsberg
// BIM - Hilfe beim Produktdatenmanagement;IQUH;25.10.2019;Rutesheim
// Zukunft der Automatisierung;81fünf - HB7;19.10.2019;Ihlenworth
// Positionierung;81fünf - HB7;18.10.2019;Ihlenworth
// BSP und der Holzhandel;Holzland;10.10.2019;Olsberg
// Kann BIM helfen?;81fünf Planererfa;09.10.2019;Berlin
// Holzbau aus Sicht der Werkstattplanung;Holzbau Summer School;25.09.2019;Hamburg
// Disruption des Holzbaus;81fünf Herbsttagung;13.09.2019;Freiburg
// Digitale Planungsprozesse im Holzbau;Verband Niedersächsischer Zimmermeister;29.08.2019;Bad Zwischenahn
// 1 - Einführung BIM im Holzbau;Holzbau Baden;15.05.2019;Freiburg
// 2 - Planungsprozesse im Holzbau;Holzbau Baden;15.05.2019;Freiburg
// 3 - Digitales Aufmaß;Holzbau Baden;15.05.2019;Freiburg
// 4 - Digitalisierung im Bauwesen;Holzbau Baden;15.05.2019;Freiburg
// 5 - BIM beginnen;Holzbau Baden;15.05.2019;Freiburg
// Planungsprozesse;Prause Holzbauplanung;13.05.2019;Lindlar
// Planungsprozesse und BIM;81fünf - HB9;11.05.2019;Spangenberg
// BIM - Chancen und Risiken für den Holzhandel;Holzland;08.04.2019;Amsterdam
// Anforderungen an eine umfassende Arbeitsvorbereitung;High Tech Abbundverband;24.01.2019;Kirchberg
// DHWR + BIM;DHWR;30.11.2018;Berlin
// BIM Leitfaden und BIM Forschung;DHV Herbsttagung;23.11.2018;Ettlingen
// Digitale Holzbauplanung;Building Smart Rheinland bim next;21.11.2018;Köln
// Kernaufgaben des Chefs;81fünf - HB7;12.10.2018;Lohne
// BIM in den Niederlanden und Deutschland;81fünf Herbsttagung;21.09.2018;Amsterdam
// Holzbauweise im Mehrfamilienhausbau;vdw Niedersachsen + Bremen;05.09.2018;Lüneburg
// Hausbau einfach gemacht;Forum Holzbau;31.08.2018;Klagenfurt
// Einführung in die Planungsmethode BIM;Handwerkskammer Erfurt;25.05.2018;Erfurt
// Die Planungsprozesse im Holzbau sind schon lange BIM;Building Smart Deutschland;18.04.2018;Nürnberg
// Digitalisierung;81fünf - HB6;13.04.2018;Bad Lippspringe
// Werkplanung - BIM gerecht umgesetzt;Beuth Holzbauforum;22.03.2018;Berlin
// BIM - nur ein Schlagwort?;Frühjahrstagung der Holzbauverbände;17.03.2018;Berlin
// Digitalisierung;GIH Baden - Württemberg;16.02.2018;Feuchtwangen
// BIM - was steckt konkret dahinter?;High Tech Abbundverband;25.01.2018;Kassel
// BIM;Zimmerer Innung Borken;11.01.2018;Borken
