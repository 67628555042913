import React from "react"
// import { graphql } from "gatsby"
import styled, { ThemeProvider } from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/contact"
// import { RichText } from "../api/contentful"
import {
  Link,
  Section,
  SectionHeading,
  Pagehead,
  PageTitle,
  Breadcrumbs,
  CallToAction,
  InquiryCallToAction,
  Paragraph,
  UnorderedList,
} from "../components/shared"
import { contrastSectionTheme } from "../components/theme"
import { DateArrangement, Inquiry, Workshop } from "../components/illustrations"
import arrowDown from "../images/arrow_down.svg"

import talks from "./_talks"

const Narrow = styled.div`
  margin: 0 auto;
  max-width: 64ch;
`

const PageSectionHeading = styled.h2`
  margin-top: 0.8em;
  margin-bottom: 0.2em;
`

const PageSectionSubheading = styled.h3`
  position: relative;
  margin-top: 1.4em;
  margin-bottom: 0.6em;
  z-index: 4;
`

const Sequence = styled.ol`
  padding: 0;
  list-style: none;
`

const StepIllustration = styled.div`
  flex: 2;
  margin-right: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 580px) {
    max-width: 50vw;
    align-items: flex-start;
  }
`

const StepText = styled.div`
  flex: 3;
  padding: 0 1em;

  @media only screen and (max-width: 580px) {
    position: relative;
    left: 15vw;
    top: -35px;
    min-width: 60%;
    width: 85%;
  }
`

const StepTitle = styled.h4`
  margin: 0 0 0.4em 0;
`

const StepDescription = styled(Paragraph)`
  font-size: 0.9rem;
`

const Step = styled.li`
  display: flex;

  &:not(:last-child) ${StepIllustration}::after {
    content: url(${arrowDown});
    display: block;
    padding: 1em 5.3vw;
    width: 30px;
  }

  @media only screen and (max-width: 580px) {
    flex-direction: column;

    &:last-child > ${StepText} {
      top: 0px;
    }
  }
`

const Trainings = styled.ul`
  display: flex;
  align-items: stretch;
  margin-left: -1em;
  padding: 0;
  overflow-x: visible;
  list-style: none;
`

const Training = styled.li`
  margin-right: 1em;
  padding: 1em;
  min-width: 60ch;
  background-color: ${props => props.theme.trainingBackgroundColor};

  @media only screen and (max-width: 666px) {
    min-width: calc(100vw - 15ch);
    /* max-width: 90vw; */
    &:first-child {
      margin-left: 1em;
    }
  }
  @media only screen and (max-width: 480px) {
    min-width: calc(100vw - 5ch);
    /* max-width: 90vw; */
  }
`

const ProspectiveTraining = styled(Training)`
  color: ${props => props.theme.prospectiveTrainingColor};
  background-color: ${props => props.theme.prospectiveTrainingBackgroundColor};

  @media only screen and (max-width: 666px) {
    &:last-child {
      min-width: calc(50vw - 10ch);
      /* max-width: 90vw; */
    }
  }
`

const TrainingTitle = styled.h4`
  font-size: 2rem;
  margin: 0 0 0.4em 0;
`

const TrainingDescription = styled.div`
  max-width: 40ch;
  padding: 0 0 1.705em 0;
`

const TrainingContents = styled(UnorderedList)`
  display: block;
  float: left;
  padding-left: 1.2em;
  max-width: 30ch;

  @media only screen and (max-width: 666px) {
    float: unset;
    max-width: 90vw;
  }
`

const InquiryCallToActionWithoutArrow = styled(InquiryCallToAction)`
  &::after {
    display: none;
  }
`

const TrainingCallToAction = styled(InquiryCallToActionWithoutArrow)`
  /* float: right; */
  display: block;
  width: calc(100% - 30ch - 20px);
  margin-left: auto;
  font-size: 1rem;
  white-space: nowrap;
  margin-top: 0em;

  @media only screen and (max-width: 666px) {
    display: inline-block;
    width: inherit;
    margin-top: 0.6em;
  }
`

const YearTalksHeader = styled.div`
  position: sticky;
  top: ${props => props.theme.shrunkHeaderHeight};
  margin-top: -1em;
  padding-top: 1em;
  z-index: 3;
  display: grid;
  grid-template-columns: 1fr 3fr 3fr;
  gap: 0 0.6em;
  font-weight: bold;
  background-color: white;
  border-bottom: 2px solid;

  @media only screen and (max-width: 666px) {
    top: ${props => props.theme.shrunkHeaderHeightNarrow};
    margin-top: -0.4em;
    padding-top: 0.4em;
  }
`

const Years = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const Year = styled.h4`
  position: sticky;
  top: calc(${props => props.theme.shrunkHeaderHeight} + 2.4em);
  align-self: start;
  margin: 0;
  padding: 1em 0;

  @media only screen and (max-width: 666px) {
    top: calc(${props => props.theme.shrunkHeaderHeightNarrow} + 1.8em);
  }
`

const Talks = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const ExpandButton = styled(CallToAction)`
  position: absolute;
  top: 1em;
  right: 1px;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 1rem;
  z-index: 2;
  cursor: pointer;

  @media only screen and (max-width: 666px) {
    background-color: white;

    > span {
      display: ${props => (props.$isCollapsed ? undefined : `none`)};
    }

    &::after {
      margin-left: ${props => (props.$isCollapsed ? undefined : 0)};
    }
  }

  &::after {
    display: inline-block;
    transform: rotate(${props => (props.$isCollapsed ? `90deg` : `270deg`)});
    transition: transform 0.4s ease-in-out;
  }
`

const CollapseButton = styled(ExpandButton)`
  top: unset;
  bottom: 1em;
  z-index: 1;
  pointer-events: ${props => (props.$isCollapsed ? `none` : undefined)};
  opacity: ${props => (props.$isCollapsed ? 0 : 1)};

  transition-property: opacity;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  transition-delay: 0.2s;

  @media only screen and (max-width: 666px) {
    background-color: white;

    > span {
      display: unset;
    }

    &::after {
      margin-left: 0.4em;
    }
  }
`

const YearTalks = styled.li`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 6fr;
  gap: 0 0.6em;
  border-bottom: 2px solid;
  max-height: ${props => (props.$isCollapsed ? `4em` : `200em`)};
  /* overflow: ${props => (props.$isCollapsed ? `hidden` : undefined)}; */

  transition-property: max-height;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  transition-delay: ${props => (props.$isCollapsed ? `0.3s` : `0`)};

  ${Year} {
    position: ${props => (props.$isCollapsed ? undefined : `sticky`)};
  }

  ${Talks} {
    opacity: ${props => (props.$isCollapsed ? 0 : 1)};
    pointer-events: ${props => (props.$isCollapsed ? `none` : undefined)};

    transition-property: opacity;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    transition-delay: ${props => (props.$isCollapsed ? `0` : `0.3s`)};
  }

  @media only screen and (max-width: 666px) {
    padding-bottom: 3em;
  }
`

const Talk = styled.li`
  padding: 1em 0;
  display: grid;
  grid-template-columns: 3fr 3fr;
  grid-template-areas:
    "title date"
    "host  place";
  gap: 0 0.6em;

  & + & {
    border-top: 2px solid;
  }
`

const TalkTitle = styled.h4`
  grid-area: title;
  margin: 0;
`

const TalkHost = styled.div`
  grid-area: host;
`

const TalkDate = styled.div`
  grid-area: date;
`

const TalkPlace = styled.div`
  grid-area: place;
`

const CustomSectionHeading = styled(SectionHeading)`
  font-size: 2.2rem;
`

const CustomDescription = styled.div`
  max-width: 40ch;
`

const today = new Date()
today.setHours(0, 0, 0, 0)

const futureTalks = talks.filter(talk => talk.date >= today)
const pastTalks = talks.filter(talk => talk.date < today)

const futureTalksYears = Array.from(
  new Set(futureTalks.map(talk => talk.date.getFullYear()))
).sort((a, b) => b - a)
const pastTalksYears = Array.from(
  new Set(pastTalks.map(talk => talk.date.getFullYear()))
).sort((a, b) => b - a)

const TalksInYear = ({ year, talks }) => {
  const [isCollapsed, setIsCollapsed] = React.useState(true)

  return (
    <YearTalks $isCollapsed={isCollapsed} key={year}>
      <ExpandButton
        as="button"
        type="button"
        title={isCollapsed ? "einblenden" : "ausblenden"}
        onClick={() => {
          setIsCollapsed(isCollapsed => !isCollapsed)
        }}
        $isCollapsed={isCollapsed}
      >
        <span>{isCollapsed ? "einblenden" : "ausblenden"}</span>
      </ExpandButton>
      <Year>{year}</Year>
      <Talks>
        {talks
          .sort((a, b) => b.date - a.date)
          .map(({ title, eventUrl, host, hostUrl, date, place }) => (
            <Talk key={`${title} ${date}`}>
              <TalkTitle>
                {eventUrl ? <Link to={eventUrl}>{title}</Link> : title}
              </TalkTitle>
              <TalkHost>
                {hostUrl ? <Link to={hostUrl}>{host}</Link> : host}
              </TalkHost>
              <TalkDate>
                {Intl.DateTimeFormat("de-DE", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                }).format(date)}
              </TalkDate>
              <TalkPlace>{place}</TalkPlace>
            </Talk>
          ))}
      </Talks>
      <CollapseButton
        // TODO: when clicking this button directly, there is no transition delay?
        as="button"
        type="button"
        title={isCollapsed ? "einblenden" : "ausblenden"}
        onClick={() => {
          setIsCollapsed(isCollapsed => !isCollapsed)
        }}
        $isCollapsed={isCollapsed}
      >
        <span>{isCollapsed ? "einblenden" : "ausblenden"}</span>
      </CollapseButton>
    </YearTalks>
  )
}

const Akademie = ({ data }) => {
  // const pageData = data.contentfulServicesPage

  return (
    <Layout>
      {/* <SEO title={pageData.title} description={pageData.metaDescription} /> */}
      <SEO title="Akademie" />
      <Pagehead bottomMargin>
        {/* <OpenerImage fluid={pageData.openerImage.fluid} alt="" /> */}
        <PageTitle>
          In unserer Akademie bereiten wir die Holzbaubranche auf die Zukunft
          vor – mit spannenden Veranstaltungen rund um die Themenwelten der
          Holzbauplanung.
        </PageTitle>
        <InquiryCallToAction>Anfrage für Schulung</InquiryCallToAction>
        <Breadcrumbs nodes={[{ title: "Akademie" }]} />
      </Pagehead>
      <Section style={{ padding: 0 }}>
        <Narrow>
          <PageSectionHeading>
            Fort- und Weiterbildungsangebote
          </PageSectionHeading>
          <Paragraph>
            Die Prause-Akademie fördert den Dialog und die Kooperation zwischen
            Architekten, Holzbauingenieuren, Fachplanern und ausführenden
            Holzbauunternehmen und Zimmereibetrieben. Unsere praxisorientierten
            Webinare, Workshops, Seminare und Vorträge vertiefen das Fachwissen
            und sind als Fort- und Weiterbildungen speziell auf die
            Arbeitsvorbereitung, digitale Planungsverfahren, integrative
            Planungsprozesse mit BIM und auf die planerischen
            Schnittstellenbereiche im Holzbau ausgerichtet. Selbstverständlich
            bieten wir alle Veranstaltungen auch als virtuelle oder hybride
            Angebote an.
          </Paragraph>
          <Paragraph>
            Wir konzipieren unsere Fort- und Weiterbildungen individuell für
            Einzelpersonen, Büros, Betriebe, Unternehmen,
            Wohnungsbaugesellschaften oder Verbände. Auf diese Weise können wir
            die Inhalte und Themen genau auf die Wünsche und Ziele der
            jeweiligen Auftraggeber*innen bzw. Teilnehmer*innen abstimmen.
          </Paragraph>
          <Paragraph>
            Nicht nur der Holzbau, auch jede einzelne Veranstaltung verlangt
            professionelle Vorbereitung. Deshalb möchten wir Sie bitten,
            folgenden Ablauf bei der Buchung der individuellen
            Fortbildungsveranstaltungen einzuhalten.
          </Paragraph>
          <PageSectionSubheading>Ablauf</PageSectionSubheading>
          <Sequence>
            <Step>
              <StepIllustration>
                <Inquiry height="100%" max-width="50vw" />
              </StepIllustration>
              <StepText>
                <StepTitle>1. Anfrage stellen</StepTitle>
                <StepDescription>
                  Geben Sie an dieser Stelle bitte Ihre Interessensgebiete und
                  thematischen Schwerpunkte an, damit wir die Veranstaltung
                  individuell auf die Teilnehmer*innen zuschneiden können. Für
                  unsere Veranstaltungsplanung ist daher ebenso interessant,
                  welche Vorkenntnisse die Teilnehmer*innen mitbringen.
                </StepDescription>
              </StepText>
            </Step>
            <Step>
              <StepIllustration>
                <DateArrangement height="100%" />
              </StepIllustration>
              <StepText>
                <StepTitle>2. Terminabstimmung</StepTitle>
                <StepDescription>
                  Wir stimmen die Veranstaltungstermine individuell mit Ihnen
                  ab, damit wir immer den bestmöglichen Expert*innen aus unserem
                  Team als Dozent*in für die gewünschte Veranstaltung einsetzen
                  können
                </StepDescription>
              </StepText>
            </Step>
            <Step>
              <StepIllustration>
                <Workshop height="100%" />
              </StepIllustration>
              <StepText>
                <StepTitle>3. Schulung</StepTitle>
                <StepDescription>
                  Die Schulungen und Veranstaltungen finden an den abgestimmten
                  Terminen statt.
                  {/* Im Anschluss jeder Veranstaltung werden
                  Handouts mit einer Zusammenfassung der wichtigsten Inhalte
                  versendet. */}
                </StepDescription>
              </StepText>
            </Step>
          </Sequence>
          <PageSectionSubheading>
            Aktuelle Fortbildungsangebote
          </PageSectionSubheading>
        </Narrow>
      </Section>
      <Section
        style={
          {
            padding: 0,
            overflowX: "auto",
          } /* TODO: hide scrollbar on Windows? */
        }
      >
        <Narrow>
          <Trainings>
            <Training>
              <TrainingTitle>
                Weiterbildung in der Arbeitsvorbereitung
              </TrainingTitle>
              <TrainingDescription>
                <Paragraph>
                  In der Planungsphase von Holzgebäuden müssen frühzeitig sehr
                  vielfältige Aspekte bedacht und integriert werden. Bei der
                  Arbeitsvorbereitung laufen alle Fäden zusammen. Deshalb nehmen
                  Arbeitsvorbereiter eine Schlüsselposition bei der Planung von
                  Gebäuden ein. Der Beruf setzt verantwortungsvolles und
                  weitsichtiges Handeln voraus. In dieser Veranstaltung möchten
                  wir unser Wissen, unsere Erfahrung und nicht zuletzt unsere
                  Begeisterung für die Arbeitsvorbereitung besonders an
                  Zimmermeister*innen, Holzbauingenieure*innen und
                  Architekt*innen weitergeben.
                </Paragraph>
              </TrainingDescription>
              <TrainingContents>
                <li>Aufgaben und Umfang der Arbeitsvorbereitung</li>
                <li>
                  Qualifikationsanforderungen für das Tätigkeitsfeld
                  Arbeitsvorbereitung
                </li>
                <li>
                  Erstellung einer umfassenden Informationsbasis für die
                  Arbeitsvorbereitung
                </li>
                <li>Beteiligte des Planungsprozesses</li>
                <li>Engpässe der Arbeitsvorbereitung</li>
                <li>Wirtschaftlichkeit der Arbeitsvorbereitung</li>
                <li>Die Zukunft der Arbeitsvorbereitung und BIM</li>
              </TrainingContents>
              <TrainingCallToAction>Anfrage stellen</TrainingCallToAction>
            </Training>
            <ProspectiveTraining>
              weitere Angebote
              <br />
              in Planung
            </ProspectiveTraining>
          </Trainings>
        </Narrow>
      </Section>
      <Section>
        <Narrow>
          <h2>Öffentliche und verbandsinterne Veranstaltungen</h2>
          <Paragraph>
            Neben unseren individualisierbaren Angeboten bieten wir zusätzlich
            öffentliche Veranstaltungen und Schulungen zu vorab festgelegten
            Themenbereichen und Schwerpunkten ab. Themen und Termine finden Sie
            in folgender Liste.
          </Paragraph>
          <PageSectionSubheading>Termine</PageSectionSubheading>
          <div>
            <YearTalksHeader>
              <span>Jahr</span>
              <span>Thema</span>
              <span>Termin</span>
            </YearTalksHeader>
            <Years>
              {futureTalksYears.map(year => (
                <YearTalks key={year}>
                  <Year>{year}</Year>
                  <Talks>
                    {futureTalks
                      .filter(talk => talk.date.getFullYear() === year)
                      .sort((a, b) => a.date - b.date)
                      .map(
                        ({ title, eventUrl, host, hostUrl, date, place }) => (
                          <Talk key={`${title} ${date}`}>
                            <TalkTitle>
                              {eventUrl ? (
                                <Link to={eventUrl}>{title}</Link>
                              ) : (
                                title
                              )}
                            </TalkTitle>
                            <TalkHost>
                              {hostUrl ? (
                                <Link to={hostUrl}>{host}</Link>
                              ) : (
                                host
                              )}
                            </TalkHost>
                            <TalkDate>
                              {Intl.DateTimeFormat("de-DE", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              }).format(date)}
                            </TalkDate>
                            <TalkPlace>{place}</TalkPlace>
                          </Talk>
                        )
                      )}
                  </Talks>
                </YearTalks>
              ))}
            </Years>
          </div>
          <PageSectionSubheading>Vergangene Termine</PageSectionSubheading>
          <div>
            <YearTalksHeader>
              <span>Jahr</span>
              <span>Thema</span>
              <span>Termin</span>
            </YearTalksHeader>
            <Years>
              {pastTalksYears.map(year => (
                <TalksInYear
                  year={year}
                  talks={pastTalks.filter(
                    talk => talk.date.getFullYear() === year
                  )}
                  key={year}
                />
              ))}
            </Years>
          </div>
        </Narrow>
      </Section>
      <ThemeProvider theme={contrastSectionTheme}>
        <Section>
          <Narrow>
            <CustomSectionHeading>
              Anfrage für eine betriebsinterne Fort- oder Weiterbildung
            </CustomSectionHeading>
            <CustomDescription>
              <Paragraph>
                Unsere betriebsinternen Weiterbildungen werden spezifisch auf
                Ihr Unternehmen abgestimmt und können - sobald dies wieder
                zugelassen ist - in Ihrem Betrieb bzw. Unternehmen oder in einem
                Veranstaltungsraum Ihrer Wahl stattfinden. Natürlich bieten wir
                diese Weiterbildungen auch als Webinare oder virtuelle Seminare
                an.
              </Paragraph>
            </CustomDescription>
            <InquiryCallToActionWithoutArrow>
              Anfrage für Schulung
            </InquiryCallToActionWithoutArrow>
          </Narrow>
        </Section>
      </ThemeProvider>
      <Contact />
    </Layout>
  )
}

export default Akademie

// export const query = graphql`
//   query LeistungenQuery {
//     contentfulServicesPage {
//       title
//       metaDescription
//       openerImage {
//         fluid {
//           ...GatsbyContentfulFluid_noBase64
//         }
//       }
//       pageTitle {
//         json
//       }
//       inquiryCallToAction
//       text1 {
//         json
//       }
//       text2 {
//         json
//       }
//       text3 {
//         json
//       }
//       closerHeading
//       closerBody: closingBody {
//         json
//       }
//     }
//   }
// `
